import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class PropertyInvestmentClub extends React.Component {
	render() {
		const siteTitle = 'Property Investment Club'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">WSC Group Property Investor Club</h3>
								<p className="my-4">
									<strong>Property Investor Club</strong>
									<br />
									WSC Group is the trusted advisor for over 3,000 property
									investors across Australia and is committed to working with
									you to grow your wealth through property. Whether you are
									starting out in your investment property journey or are near
									realising your goals, WSC Group’s Property Tax Specialist Team
									working with you to get you the results that you need.
								</p>
								<h5>WHY JOIN?</h5>
								<ul>
									<li>
										Keep abreast of our property tax service offerings and tools
										to assist you along your wealth creation journey.
									</li>
									<li>
										Take advantage of WSC Group’s partnership with other like
										minded property focused experts.
									</li>
									<li>You control the level of service you want.</li>
									<li>
										Pay by the month instalments allow you to manage your
										cash-flow giving you control over your funds and allowing
										you to focus on your investment decisions.
									</li>
								</ul>
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/dbf4c11649cdd616fb9c6967d3cc3ffa389699b4/investors_club_table.jpg"
									alt="Property Investor Club Stats"
								/>
								<p>
									<strong>Payment plan terms and conditions:</strong>
								</p>
								<hr />
								<div className="row">
									<div className="col">
										The 12 month payment terms for The Club commence on 1
										August. If the client enters the club after this date, the
										first instalment will be pro-rated to include any
										instalments between 1 August and the date of joining the
										club. The fees of The Club are based on a standard tax
										return and standard rental schedule only. Additional fees
										may be incurred to work outside these standard items.
										Charges for these separate items will be invoiced separately
										upon completion of the tax return and will be payable within
										14 days. Please refer to the WSC Group Fee Schedule for
										further information regarding these additional fees. Fees
										based on the number of properties held at 31 July. All
										instalments must be paid on or before the due date and in
										accord with the Ezidebit terms and conditions (refer to
										Ezidebit agreement for further information).
									</div>
									<div className="col">
										WSC Group reserves the right to suspend access to a client’s
										Document Portal facility and/or Real Estate Investar
										facility, where applicable, upon nonpayment of fees. In the
										event that a client cancels their Ezidebit repayments, the
										balance of all fees will be paid in full within seven (7)
										days of cancellation or the debt will be forwarded to a debt
										collection agency, and the client may also be required to
										pay additional fees associated with the debt collection
										process. The Client must keep WSC Group updated with their
										contact information. Platinum membership only available to
										WSC Group clients who have been continuous clients for three
										(3) consecutive tax periods and have a share in at least
										five (5) investments properties in their personal names.
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default PropertyInvestmentClub

export const PropertyInvestmentClubPageQuery = graphql`
	query PropertyInvestmentClubPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
